<template>
  <div class="pb-6">
    <form
      class="mt-4"
      @input="updateLayout()"
      @submit.prevent
    >
      <div class="columns is-multiline">
        <div class="column is-3">
          <h1 class="is-size-4">
            General
          </h1>
        </div>
        <div class="column is-9">
          <!--          <b-field-->
          <!--            label="Primary color"-->
          <!--          >-->
          <!--            <verte-->
          <!--              v-model="layout.data.colors.primary"-->
          <!--              class="is-left"-->
          <!--              picker="square"-->
          <!--              model="hex"-->
          <!--              style="width: 20px"-->
          <!--              @input="updateLayout"-->
          <!--            />-->
          <!--          </b-field>-->
          <!--          <b-field-->
          <!--            label="Secondary color"-->
          <!--          >-->
          <!--            <verte-->
          <!--              v-model="layout.data.colors.secondary"-->
          <!--              class="is-left"-->
          <!--              picker="square"-->
          <!--              model="hex"-->
          <!--              style="width: 20px"-->
          <!--              @input="updateLayout"-->
          <!--            />-->
          <!--          </b-field>-->
          <b-field label="Name">
            <b-input
              v-model="layout.name"
            />
          </b-field>
          <b-field label="Logo">
            <b-upload
              v-model="file"
              preven
              native
              @input="handleUpload('logo');"
            >
              <span class="file-cta">
                <b-icon
                  class="file-icon"
                  icon="upload"
                />
                <span class="file-label">Click to upload the logo</span>
              </span>
              <span
                v-if="layout.data.logo"
                style="max-width: 300px; max-height: 200px; overflow: hidden"
              >
                <img
                  :src="layout.data.logo"
                  width="300"
                  alt="Logo"
                >
              </span>
            </b-upload>
          </b-field>
          <b-field>
            <b-switch
              v-model="layout.data.logoOptions.enableNavigation"
              @input="updateLayout"
            >
              Enable navigation on logo
            </b-switch>
          </b-field>

          <div
            v-if="layout.data.logoOptions.enableNavigation"
            class="columns is-flex-wrap-wrap"
          >
            <div class="column is-6">
              Type
            </div>
            <div class="column is-6">
              Value
            </div>
            <div class="column is-6">
              <b-select
                v-model="layout.data.logoOptions.link"
                class="is-small"
                expanded
                size="is-small"
                @input="updateLayout"
              >
                <option value="module">
                  Link to module
                </option>
                <option value="custom">
                  Link to custom link
                </option>
              </b-select>
            </div>
            <div class="column is-6">
              <b-field v-if="layout.data.logoOptions.link === 'module'">
                <b-select
                  v-model="layout.data.logoOptions.moduleId"
                  size="is-small"
                  expanded
                  @input="updateLayout"
                >
                  <option
                    v-for="(module, mIndex) in modules"
                    :key="mIndex"
                    :value="module.id"
                  >
                    {{ module.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field v-else-if="layout.data.logoOptions.link === 'custom'">
                <b-input
                  v-model="layout.data.logoOptions.remoteUrl"
                  size="is-small"
                  expanded
                  @input="updateLayout"
                />
              </b-field>
            </div>
          </div>
          <b-field label="Title">
            <b-input
              v-model="layout.data.title"
            />
          </b-field>
          <b-field label="Fav Icon">
            <b-upload
              v-model="file"
              preven
              native
              @input="handleUpload('favIcon');"
            >
              <span class="file-cta mr-4">
                <b-icon
                  class="file-icon"
                  icon="upload"
                />
                <span class="file-label">Click to upload the Fav Icon</span>
              </span>
              <span
                v-if="layout.data.favIcon"
                style="max-width: 32px; max-height: 32px; overflow: hidden"
              >
                <img
                  :src="layout.data.favIcon"
                  width="300"
                  alt="Fav Icon"
                >
              </span>
            </b-upload>
          </b-field>
        </div>
      </div>
      <hr>
      <div class="columns">
        <div class="column is-3">
          <h1 class="is-size-4">
            Header
          </h1>
        </div>
        <div class="column is-9">
          <b-field label="Header options">
            <b-switch
              v-model="layout.data.header.show"
              @input="updateLayout"
            >
              Show header
            </b-switch>
          </b-field>
          <div v-if="layout.data.header.show">
            <b-field>
              <b-switch
                v-model="layout.data.header.logo.show"
                @input="updateLayout"
              >
                Show logo in the header
              </b-switch>
            </b-field>
            <div
              v-if="layout.data.header.logo.show"
              class="my-2"
            >
              <small class="is-block">Logo position</small>
              <b-select
                v-model="layout.data.header.logo.position"
                size="is-small"
                @input="updateLayout"
              >
                <option value="left">
                  Left
                </option>
                <option value="right">
                  Right
                </option>
              </b-select>
            </div>

            <b-field>
              <b-switch
                v-model="layout.data.header.navigation.show"
                @input="updateLayout"
              >
                Has navigation
              </b-switch>
            </b-field>
            <b-field>
              <b-switch
                v-model="layout.data.header.signout.show"
                @input="updateLayout"
              >
                Show logout
              </b-switch>
            </b-field>
            <div v-if="layout.data.header.navigation.show">
              <div class="columns">
                <div class="column is-1" />
                <div class="column is-3">
                  Type
                </div>
                <div class="column is-4">
                  Label
                </div>
                <div class="column is-3">
                  Value
                </div>
              </div>
              <draggable
                v-model="layout.data.header.navigation.items"
                group="people"
                @end="updateLayout"
              >
                <div
                  v-for="(item, index) in layout.data.header.navigation.items"
                  :key="index"
                >
                  <div class="columns is-multiline">
                    <div class="column is-1">
                      <b-icon
                        icon="drag"
                      />
                    </div>
                    <div class="column is-3">
                      <b-select
                        v-model="item.link"
                        class="is-small"
                        expanded
                        size="is-small"
                        @input="updateLayout"
                      >
                        <option value="module">
                          Link to module
                        </option>
                        <option value="custom">
                          Link to custom link
                        </option>
                      </b-select>
                    </div>
                    <div class="column is-4">
                      <b-field>
                        <b-input
                          v-model="item.label"
                          size="is-small"
                          expanded
                          @input="updateLayout"
                        />
                      </b-field>
                    </div>
                    <div class="column is-3">
                      <b-field v-if="item.link === 'module'">
                        <b-select
                          v-model="item.moduleId"
                          size="is-small"
                          expanded
                          @input="updateLayout"
                        >
                          <option
                            v-for="(module, mIndex) in modules"
                            :key="mIndex"
                            :value="module.id"
                          >
                            {{ module.name }}
                          </option>
                        </b-select>
                      </b-field>
                      <b-field v-else-if="item.link === 'custom'">
                        <b-input
                          v-model="item.remoteUrl"
                          size="is-small"
                          expanded
                          @input="updateLayout"
                        />
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-button
                        icon-left="delete"
                        size="is-small"
                        type="is-danger"
                        expanded
                        @click="deleteNav('header', index)"
                      />
                    </div>
                  </div>
                </div>
              </draggable>
              <b-button
                size="is-small"
                expanded
                type="is-dark"
                class="mt-3"
                @click="addNavigation('header')"
              >
                Add navigation item
              </b-button>
            </div>
            <template
              v-if="layout.data.header.signout.show || layout.data.sidebar.signout.show"
            >
              <strong class="is-block mt-3 mb-2">Dropdown Options:</strong>
              <div
                class="columns mb-0"
              >
                <div class="column is-2">
                  <strong>Name</strong>
                </div>
                <div class="column is-4">
                  <strong>Text</strong>
                </div>
              </div>
              <div
                v-for="(appOption, aOIndex) in layout.data.appOptions"
                :key="'appOption' + aOIndex"
                class="columns"
              >
                <div class="column is-2">
                  {{ appOption.name }}
                </div>
                <div class="column is-4">
                  <b-field>
                    <b-input
                      v-model="appOption.text"
                      size="is-small"
                      expanded
                      @input="updateLayout"
                    />
                  </b-field>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <hr>
      <div class="columns">
        <div class="column is-3">
          <h1 class="is-size-4">
            Sidebar
          </h1>
        </div>
        <div class="column is-9">
          <b-field label="Sidebar options">
            <b-switch
              v-model="layout.data.sidebar.show"
              @input="updateLayout"
            >
              Show sidebar
            </b-switch>
          </b-field>
          <div v-if="layout.data.sidebar.show">
            <small class="is-block">Sidebar position</small>
            <b-select
              v-model="layout.data.sidebar.position"
              size="is-small"
              class="mb-3"
              @input="updateLayout"
            >
              <option value="left">
                Left
              </option>
              <option value="right">
                Right
              </option>
            </b-select>

            <b-field>
              <b-switch
                v-model="layout.data.sidebar.logo.show"
                @input="updateLayout"
              >
                Show logo in the sidebar
              </b-switch>
            </b-field>
            <b-field>
              <b-switch
                v-model="layout.data.sidebar.navigation.show"
                @input="updateLayout"
              >
                Has navigation
              </b-switch>
            </b-field>
            <b-field>
              <b-switch
                v-model="layout.data.sidebar.signout.show"
                @input="updateLayout"
              >
                Show logout
              </b-switch>
            </b-field>
            <div v-if="layout.data.sidebar.navigation.show">
              <div class="columns">
                <div class="column is-1" />
                <div class="column is-3">
                  Type
                </div>
                <div class="column is-4">
                  Label
                </div>
                <div class="column is-3">
                  Value
                </div>
              </div>
              <draggable
                v-model="layout.data.sidebar.navigation.items"
                @end="updateLayout"
              >
                <div
                  v-for="(item, index) in layout.data.sidebar.navigation.items"
                  :key="index"
                >
                  <div class="columns is-multiline">
                    <div class="column is-1">
                      <b-icon icon="drag" />
                    </div>
                    <div class="column is-3">
                      <b-select
                        v-model="item.link"
                        class="is-small"
                        expanded
                        size="is-small"
                        @input="updateLayout"
                      >
                        <option value="module">
                          Link to module
                        </option>
                        <option value="custom">
                          Link to custom link
                        </option>
                      </b-select>
                    </div>
                    <div class="column is-4">
                      <b-field>
                        <b-input
                          v-model="item.label"
                          size="is-small"
                          expanded
                          @input="updateLayout"
                        />
                      </b-field>
                    </div>
                    <div class="column is-3">
                      <b-field v-if="item.link === 'module'">
                        <b-select
                          v-model="item.moduleId"
                          size="is-small"
                          expanded
                          @input="updateLayout"
                        >
                          <option
                            v-for="(module, mIndex) in modules"
                            :key="mIndex"
                            :value="module.id"
                          >
                            {{ module.name }}
                          </option>
                        </b-select>
                      </b-field>
                      <b-field v-else-if="item.link === 'custom'">
                        <b-input
                          v-model="item.remoteUrl"
                          size="is-small"
                          expanded
                          @input="updateLayout"
                        />
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-button
                        icon-left="delete"
                        size="is-small"
                        type="is-danger"
                        expanded
                        @click="deleteNav('sidebar', index)"
                      />
                    </div>
                  </div>
                </div>
              </draggable>
              <b-button
                size="is-small"
                expanded
                type="is-dark"
                class="mt-3"
                @click="addNavigation('sidebar')"
              >
                Add navigation item
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="columns">
        <div class="column is-3">
          <h1 class="is-size-4">
            Footer
          </h1>
        </div>
        <div class="column is-9">
          <b-field label="Footer options">
            <b-switch
              v-model="layout.data.footer.show"
              @input="updateLayout"
            >
              Show footer
            </b-switch>
          </b-field>
          <div v-if="layout.data.footer.show">
            <b-switch v-model="layout.data.footer.copyRight.show">
              Show copy right text
            </b-switch>
            <div
              v-if="layout.data.footer.copyRight.show"
              class="my-3"
            >
              <small class="is-block">Copyright text</small>
              <b-input
                v-model="layout.data.footer.copyRight.text"
                size="is-small"
                expanded
                @input="updateLayout"
              />
            </div>
            <b-field>
              <b-switch
                v-model="layout.data.footer.logo.show"
                @input="updateLayout"
              >
                Show logo
              </b-switch>
            </b-field>
            <b-field>
              <b-switch
                v-model="layout.data.footer.navigation.show"
                @input="updateLayout"
              >
                Has navigation
              </b-switch>
            </b-field>
            <div v-if="layout.data.footer.navigation.show">
              <div class="columns">
                <div class="column is-3">
                  Type
                </div>
                <div class="column is-4">
                  Label
                </div>
                <div class="column is-4">
                  Value
                </div>
              </div>
              <draggable
                v-model="layout.data.footer.navigation.items"
                @end="updateLayout"
              >
                <div
                  v-for="(item, index) in layout.data.footer.navigation.items"
                  :key="index"
                >
                  <div class="columns is-multiline">
                    <div class="column is-1">
                      <b-icon icon="drag" />
                    </div>
                    <div class="column is-3">
                      <b-select
                        v-model="item.link"
                        class="is-small"
                        expanded
                        size="is-small"
                        @input="updateLayout"
                      >
                        <option value="module">
                          Link to module
                        </option>
                        <option value="custom">
                          Link to custom link
                        </option>
                      </b-select>
                    </div>
                    <div class="column is-4">
                      <b-field>
                        <b-input
                          v-model="item.label"
                          size="is-small"
                          expanded
                          @input="updateLayout"
                        />
                      </b-field>
                    </div>
                    <div class="column is-3">
                      <b-field v-if="item.link === 'module'">
                        <b-select
                          v-model="item.moduleId"
                          size="is-small"
                          expanded
                          @input="updateLayout"
                        >
                          <option
                            v-for="(module, mIndex) in modules"
                            :key="mIndex"
                            :value="module.id"
                          >
                            {{ module.name }}
                          </option>
                        </b-select>
                      </b-field>
                      <b-field v-else-if="item.link === 'custom'">
                        <b-input
                          v-model="item.remoteUrl"
                          size="is-small"
                          expanded
                          @input="updateLayout"
                        />
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-button
                        icon-left="delete"
                        size="is-small"
                        type="is-danger"
                        expanded
                        @click="deleteNav('sidebar', index)"
                      />
                    </div>
                  </div>
                </div>
              </draggable>
              <b-button
                size="is-small"
                expanded
                type="is-dark"
                class="mt-3"
                @click="addNavigation('footer')"
              >
                Add navigation item
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script >
// lib
import draggable from 'vuedraggable';
import { onMounted, ref } from '@vue/composition-api';
import axios from 'axios';
// services
import { fetchLayoutByIdService, updateLayoutService } from '@/services/application-service/layoutRequests';
import { fetchModulesService } from '@/services/application-service/moduleRequests';
// composables
import { useRoute } from '@/hooks/vueRouter';
// others
import { API_TYPE_ID, SCHEDULED_JOB_TYPE_ID, WEB_APP_TYPE_ID } from '@/constants/moduleTypes';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const file = ref([]);
  const layout = ref({
    name: '',
    data: {
      colors: {
        primary: '#444444',
        secondary: '#BADA55'
      },
      logo: '',
      logoOptions: {
        enableNavigation: false,
        link: 'module',
        moduleId: null,
        remoteUrl: ''
      },
      title: '',
      favIcon: '',
      appOptions: [{
        name: 'Sign Out',
        text: ''
      }],
      header: {
        show: false,
        logo: {
          show: false,
          position: 'left'
        },
        title: {
          text: '',
          position: 'left'
        },
        navigation: {
          show: false,
          items: [{
            label: '',
            link: 'module',
            moduleId: null,
            remoteUrl: ''
          }]
        },
        signout: {
          show: false
        }
      },
      sidebar: {
        show: false,
        position: 'left',
        logo: {
          show: false,
          position: 'left'
        },
        location: 'left',
        navigation: {
          show: false,
          items: [{
            label: '',
            link: 'module',
            moduleId: null,
            remoteUrl: ''
          }]
        },
        signout: {
          show: false
        }
      },
      footer: {
        show: false,
        logo: {
          show: false,
          position: 'left'
        },
        copyRight: {
          show: true,
          text: 'Copyright © 2022'
        },
        navigation: {
          show: false,
          items: [{
            label: '',
            link: 'module',
            moduleId: null,
            remoteUrl: ''
          }]
        }
      }
    }
  });
  const modules = ref([]);
  onMounted(() => {
    fetchLayout();
    fetchModules();
  });
  const fetchLayout = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchLayoutByIdService(route.params.appId, route.params.layoutId);
      layout.value.name = data.name;
      if (data.data !== '') {
        const fetchedLayoutData = JSON.parse(data.data);
        layout.value.data = {
          ...layout.value.data,
          ...fetchedLayoutData,
          colors: {
            ...layout.value.data.colors,
            ...fetchedLayoutData.colors
          },
          header: {
            ...layout.value.data.header,
            ...fetchedLayoutData.header
          },
          sidebar: {
            ...layout.value.data.sidebar,
            ...fetchedLayoutData.sidebar
          },
          footer: {
            ...layout.value.data.footer,
            ...fetchedLayoutData.footer
          }
        };
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchModules = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchModulesService(route.params.appId, {
        typeIds: [WEB_APP_TYPE_ID, API_TYPE_ID, SCHEDULED_JOB_TYPE_ID]
      });
      modules.value = data;
    } catch (err) {
      console.log(err);
    }
  };
  const updateLayout = async () => {
    if (layout.value.name === '') {
      return false;
    }
    try {
      const {
        data: {
          data
        }
      } = await updateLayoutService(route.params.appId, route.params.layoutId, layout.value);
    } catch (err) {
      console.log(err);
    }
  };
  const addNavigation = async section => {
    layout.value.data[section].navigation.items.push({
      label: '',
      link: 'module',
      moduleId: null,
      remoteUrl: ''
    });
    updateLayout();
  };
  const deleteNav = async (section, index) => {
    layout.value.data[section].navigation.items.splice(index, 1);
    await updateLayout();
  };
  const handleUpload = async uploadType => {
    let formData = new FormData();
    formData.append('file', file.value);

    // ignore replacing with services for now
    const {
      data: {
        data
      }
    } = await axios.post(window.env.VUE_APP_APP_SERVICE_DOMAIN + '/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    layout.value.data[uploadType] = window.env.VUE_APP_APP_SERVICE_DOMAIN + '/' + formData.get('file').name;
    await updateLayout();
  };
  return {
    file,
    layout,
    modules,
    updateLayout,
    addNavigation,
    deleteNav,
    handleUpload
  };
};
__sfc_main.components = Object.assign({
  draggable
}, __sfc_main.components);
export default __sfc_main;
</script>

<style>


</style>